<template>
  <v-container fluid>
    <v-row v-if="loader">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-progress-circular indeterminate color="success"/>
      </v-col>
    </v-row>
    <v-row v-if="!loader">
      <v-col>
        <span>
          <span class="d-flex flex-row align-center">
            <v-btn v-if="$route.path.startsWith('/registers/close')" text class="mx-2" @click="()=>{this.$router.go(-1)}">
              <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            <h1>Close Register</h1>
          </span>
          <span>
            <span class="d-flex flex-column">
              <h3>Register Details</h3>
              <span>Opening Time: {{utils.formatDate(register.openedAt, 'withTime')}} by {{lookupUsername(register.openedBy)}}</span>
              <span>Opening Notes:
                <pre class="pl-5">{{register.openingNotes}}</pre>
              </span>
            </span>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!loader">
      <v-col>
        <h2>Payment Summary</h2>
        <table class="text-center" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-left">Type</th>
              <th class="text-right">Expected</th>
              <th class="" style="width: 200px;">Counted</th>
              <th class="text-right">Differences</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(pt, i) of data.payments">
              <tr class="level1Row" v-if="pt.paymentTypeId!=getGlobalValue('cashPayment')" :key="i">
                <td style="font-size: 16px;" class="text-left">{{pt.PaymentType.name}}</td>
                <td class="text-right">{{utils.formatCurrency(pt.metadata.expected)}}</td>
                <td class="px-5"><v-text-field outlined type="number" dense hide-details v-model="pt.amount"/></td>
                <td class="text-right">{{utils.formatCurrency(pt.metadata.expected-pt.amount)}}</td>
              </tr>
              <template v-else>
                <tr class="level1Row" :key="i">
                  <td style="font-size: 16px;" class="text-left">{{pt.PaymentType.name}}</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.expected)}}</td>
                  <td class="px-5">
                    <span class="d-flex flex-row justify-space-around align-center mb-1">
                      <v-btn @click="toggleBreakdownView" fab color="info" x-small><v-icon>mdi-cash-sync</v-icon></v-btn>
                      <span v-if="showBreakdown">TOTAL: {{utils.formatCurrency(pt.amount)}}</span>
                      <v-text-field v-else v-model="pt.amount" label="Amount" outlined type="number" dense hide-details/>
                    </span>
                    <span v-if="showBreakdown">
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x100"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $100</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x50"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $50</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x20"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $20</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x10"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $10</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x5"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $5</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x1"/>
                        <span style="width: 100px;" class="pl-1 text-left">x $1</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x025"/>
                        <span style="width: 100px;" class="pl-1 text-left">x 25¢</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x010"/>
                        <span style="width: 100px;" class="pl-1 text-left">x 10¢</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x05"/>
                        <span style="width: 100px;" class="pl-1 text-left">x 5¢</span>
                      </span>
                      <span class="d-flex flex-row align-center">
                        <v-text-field @change="computeBreakdownTotal" outlined type="number" dense hide-details v-model="pt.metadata.amountBreakdown.x01"/>
                        <span style="width: 100px;" class="pl-1 text-left">x 1¢</span>
                      </span>
                    </span>
                  </td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.expected-pt.amount)}}</td>
                </tr>
                <tr class="pl-5 level1Row cashRow" :key="i+'2nd'">
                  <td>
                    <span class="d-flex flex-row justify-space-between">
                      <span style="width: 33%;" class="pl-5 text-left">Cash Movements</span>
                      <span style="width: 33%;" class="text-center"><b>User</b></span>
                      <span style="width: 33%;" class="text-left"><b>Time</b></span>
                    </span>
                  </td>
                  <td class="text-right"><b>Amount</b></td>
                  <td colspan="2" class="text-right"><b>Reason</b></td>
                </tr>
                <template v-for="(trans, ti) in cashMovementTransactions">
                  <tr class="level2Row cashRow" :key="i+'2nd'+ti">
                    <td>
                    <span class="d-flex flex-row justify-space-between">
                      <span style="width: 33%;" class="text-right"></span>
                      <span style="width: 33%;" class="text-center">{{lookupUsername(trans.receivedBy)}}</span>
                      <span style="width: 33%;" class="text-left">{{`${utils.formatDate(trans.createdAt)} ${utils.formatTime(trans.createdAt)}`}}</span>
                    </span>
                    </td>
                    <td :class="`${trans.transactionType===5?'red--text':''} ${trans.metadata?.type==='Opening Float'?'blue&#45;&#45;text':''} text-right px-2`">{{utils.formatCurrency(trans.amount)}}</td>
                    <td colspan="2" class="text-right">{{trans.metadata?.type==='Opening Float'?'Opening Float':(trans.notes||'None')}}</td>
                  </tr>
                </template>
                <tr class="level1Row" :key="i+'3rd'">
                  <td class="pl-5 text-left">Cash Payments From Orders</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.cashReceived)}}</td>
                  <td/>
                  <td/>
                </tr>
                <tr class="level1Row" :key="i+'4th'">
                  <td class="pl-5 text-left">Cash Redeemed</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.cashRedeemed)}}</td>
                  <td/>
                  <td/>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row v-if="!loader">
      <v-col>
        <v-textarea outlined label="Closing Notes" v-model="data.closingNotes"/>
        <!-- <v-btn color="success" :loading="closeRegisterDialog.isLoading" @click="closeRegisterDialog.isOpen = true">Close Register</v-btn> -->
        <confirmedActionButton 
          color="success"   
          buttonText="Close Register"
          requireUsername
          requirePassword
          :loading="closeRegisterDialog.isLoading" 
          @cb="closeRegister"
          fabIcon="mdi-close"
          :dialogText="'Are you sure you want to close the register? This is a non-reversible action.'"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- <v-dialog v-model="closeRegisterDialog.isOpen" width="600" height="600">
      <v-card>
        <v-card-title>
          <span>Close Register</span>
        </v-card-title>
        <v-card-text>
          <span>Are you sure you want to close the register? This is a non-reversible action.</span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeRegister" :loading="closeRegisterDialog.isLoading" color="success">Close Register</v-btn>
          <v-btn @click="closeRegisterDialog.isOpen = false" color="error" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>
<style>
table{
  border-collapse: collapse;
}
.level1Row{
  border-top: 1px solid #ddd;
}
.level1Row>td{
  padding: 5px 0 5px 0;
  vertical-align: top;
}
.level2Row{
  border-top: 1px solid #ddd;
}
</style>
<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from "axios";
import utils from "../../plugins/helpers"
import confirmedActionButton from '../../components/confirmedActionButton.vue';

export default {
  components: {
    confirmedActionButton
  },
  name: 'Home',
  data () {
    return {
      utils: utils,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      loader: false,

      data: {
        closingFloat: 0,
        closingNotes: '',
        payments: []
      },

      register: {},
      cashMovementTransactions: [],
      paymentTypes: [],

      showBreakdown: false,

      closeRegisterDialog: {
        isOpen: false,
        isLoading: false
      }
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranch', 'getCurrentRegister', 'lookupUsername', 'getGlobalValue'])
  },
  async mounted(){
    try {
      this.loader = true;

      let res = await axios.get(`${this.getEndpoint}/api/registers/sessionWithTransactions/${this.$route.params.id||this.getCurrentRegister}`)
      if(res.data.error) throw res.data.error
      this.register = res.data.data

      if(this.register.closedAt){
        await this.$router.push(`/registers/view/${this.$route.params.id||this.getCurrentRegister}`)
      }

      this.cashMovementTransactions = this.register?.Transactions?.filter(x => x.transactionType===4 || x.transactionType===5)
      this.data.payments = this.register.formattedOutput||[];

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.loader = false;
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRegister']),
    computeBreakdownTotal(){
      let total = 0;

      let found = this.data.payments.find(x => x.paymentTypeId==this.getGlobalValue('cashPayment'));
      if(found){
        total+=parseInt(found.metadata.amountBreakdown.x100||0)*100;
        total+=parseInt(found.metadata.amountBreakdown.x50||0)*50;
        total+=parseInt(found.metadata.amountBreakdown.x20||0)*20;
        total+=parseInt(found.metadata.amountBreakdown.x10||0)*10;
        total+=parseInt(found.metadata.amountBreakdown.x5||0)*5;
        total+=parseInt(found.metadata.amountBreakdown.x1||0)*1;
        total+=parseInt(found.metadata.amountBreakdown.x025||0)*0.25;
        total+=parseInt(found.metadata.amountBreakdown.x010||0)*0.10;
        total+=parseInt(found.metadata.amountBreakdown.x05||0)*0.05;
        total+=parseInt(found.metadata.amountBreakdown.x01||0)*0.01;
      }

      found.amount = utils.pff2(total);
    },
    toggleBreakdownView(){
      let found = this.data.payments.find(x => x.paymentTypeId==this.getGlobalValue('cashPayment'));
      if(!found) return;
      for(let key in Object.keys(found.metadata.amountBreakdown)){
        found.metadata.amountBreakdown[key] = 0;
      }
      found.amount = 0;

      this.showBreakdown = !this.showBreakdown;
    },
    async closeRegister(obj){
      try{
        this.closeRegisterDialog.isLoading = true;

        let temp = this.$route.params.id||this.getCurrentRegister

        let res = await axios.put(`${this.getEndpoint}/api/registers/closeRegister/${this.$route.params.id||this.getCurrentRegister}`, {data: this.data, auth: obj})
        if(res.data.error) throw res.data.error
        this.updateCurrentRegister(res.data.registerUUID)

        this.snack(`Register Closed!`, "success");

        if(temp) await this.$router.replace(`/registers/view/${temp}`)
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.closeRegisterDialog.isLoading = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
